const { default: axios } = require('axios');

require('./bootstrap');

require('owl.carousel') ;

import barba from '@barba/core';
import barbaCss from '@barba/css';

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

import 'particles.js/particles';
import IFrame from './adminlte/IFrame';
const particlesJS = window.particlesJS;

import 'isotope-layout';
import Isotope from 'isotope-layout';

// $(document).ready(function() {
//     $('#dataTable').DataTable( {
//         dom: 'Bfrtip',
//         buttons: [
//             'copy', 'csv', 'excel', 'pdf', 'print'
//         ]
//     } );
// } );

// Call the dataTables jQuery plugin
// Ajax call for estimates
// Navigation submenu animate
// Owl Carousel Init
$(document).ready(function() {
//   let table = $('#dataTable').DataTable({
//     // lengthChange: false, /* Hide result entries change selector */
//     buttons: [ 
//         {
//             text : '<i class="fas fa-print"></i> <span class="d-inline-block"> Imprimir</span>',
//             extend : 'print',
//             className : 'btn btn-secondary btn-sm shadow-sm'
//         }, 
//         {
//             text : '<i class="fas fa-note"></i> <span class="d-inline-block"> PDF</span>',
//             extend : 'pdf',
//             className : 'btn btn-secondary btn-sm shadow-sm'
//         }, 
//         // 'excel', 
//         // 'pdf', 
//         // 'colvis'
//     ]
//   });

//   table.buttons().container()
//   .removeClass('btn-group')
//   .addClass('d-flex justify-content-end align-items-center')
//   .appendTo( '#dataTable_buttons_wrapper' );
//   .appendTo( '#dataTable_wrapper .row:eq(2) .col-sm-12:eq(0)' );


    $('.nav-link-tree').click(function() {
        let $secondaryNav = $('.secondary-navigation-list');
        let $secondaryNavAnimation = $secondaryNav;
        if($secondaryNav.hasClass('active')) {
            $secondaryNavAnimation = $secondaryNav.delay(300)
        }
        $secondaryNavAnimation.slideToggle(300);
        $secondaryNav.toggleClass('active')
    })


    
});

function videoLazyLoader() {
    $("video.lazy-load-video source").each(function(){
        let sourceFile = $(this).attr('data-src');
        $(this).attr('src',sourceFile);
        let video = this.parentElement;
        video.load();
        video.play();
    });
    console.log('triggered videoLazy')
}

function setOwlCarousel() {
    let $owl = $( '.owl-carousel' );
    $owl.on('initialized.owl.carousel', function(event){ 
        // Do something
        // videoLazyLoader();
        
    });
    $owl.owlCarousel({
        loop:true,
        items:1,
        autoplay:true,
        autoplayTimeout:8000,
        autoplayHoverPause:true,
        nav:true
    });
}

function setEstimateButton() {        
    $('button.get-estimate').click(function() {

        var bodyFormData = new FormData();

        $('input').each(function(input) {
            if(localStorage.getItem('model_id') !== null) {
                bodyFormData.append('model_id', localStorage.getItem('model_id'));
                console.log('appended: {' + 'model_id' + ', ' + localStorage.getItem('model_id') + '}')
            }
            if(this.type == 'radio' && this.checked) {
                bodyFormData.append(this.name, this.value);
                console.log('appended: {' + this.name + ', ' + this.value + '}')
            }
            if(this.type !== 'radio') {
                bodyFormData.append(this.name, this.value);
                console.log('appended: {' + this.name + ', ' + this.value + '}')
            }
        })

        axios({
            method: 'post',
            url: '/api/quote',
            data: bodyFormData,
            headers: {
                'Content-Type': 'multipart/form-data' ,
            }
        })
        .then(function (response) {
            //handle success
            console.log(response);
            console.log(bodyFormData);
            showEstimate(response.data.estimate);
            
            // listen for change in input to enable estimate button
            let labels = document.querySelectorAll('.custom-control-label');
            labels.forEach(item => {
                item.addEventListener('click', function(event) {
                    toggleEstimateBtnClass()
                    removeEstimateBtnDisabled()
                })
            })


            let estimate_id = $('input[name=model_id]');
            if(!estimate_id.length){
                $('<input>', {
                    type: 'hidden',
                    id: 'model_id',
                    name: 'model_id',
                    value: response.data.model_id,
                }).appendTo('#cotizaForm');
            } else {
                estimate_id[0].value = response.data.model_id;
            }

            let submitBtn = $('#submit-btn');
            if(submitBtn.hasClass('d-none')){
                submitBtn.prop('disabled',false);
                submitBtn.removeClass('d-none');
            }
            localStorage.setItem('model_id', response.data.model_id)
        })
        .catch(function (response) {
            //handle error
            console.log(response);
            console.log(bodyFormData);
        });
    });
}

function setParticlesJs() {
    if( document.getElementById('particles-js') !== null ){
        /* particlesJS.load(@dom-id, @path-json, @callback (optional)); */
        particlesJS.load('particles-js', 
            'js/js-particles.json', 
            function() {
                // set min height
                let content = document.querySelector('#particles-js > .content');
                let canvas = document.querySelector('#particles-js').style.setProperty('--min-particles-height', Math.ceil(content.offsetHeight)+'px');
            }
        );
    };
}

//GSAP

var scrollInstances = null;
function setScrollTriggerHome() {
    var sections = gsap.utils.toArray('.item');
    sections.forEach((section) => {
        let height = Math.ceil(section.offsetHeight);
        section.style.setProperty('--min-height-items', Math.floor(section.offsetHeight)+"px");
        let instance = gsap.to(section, { 
            scrollTrigger: {
                trigger: section,
                start: 'top 56%',
                end: '+='+height,
                // markers: true,
                toggleClass: "current"
            }
        });
        (scrollInstances = scrollInstances || []).push(instance);
    });
    
    
    if(scrollInstances) {
        scrollInstances.forEach(instance => {
            instance.play();
        })
    }
}

function killScrollTriggerHome() {
    if(scrollInstances) {
        scrollInstances.forEach(instance => {
            instance.scrollTrigger.kill();
            instance.kill();
        })
        scrollInstances = null;
    }
}


function toggleEstimateBtnClass(){
    let estimateBtn = $('#estimate-btn');
    if(estimateBtn.prop('disabled')){
        estimateBtn.toggleClass('d-none');  
    }

    console.log('Estimate button toggled class')
}

function removeEstimateBtnClass(){
    let estimateBtn = $('#estimate-btn');
    if(estimateBtn.prop('disabled')){
        estimateBtn.toggleClass('d-none');  
        estimateBtn.removeClass('btn-primary');
        estimateBtn.addClass('btn-secondary');      
    }

    console.log('Estimate button toggled class')
}

function toggleEstimateBtnDisabled(){
    let estimateBtn = $('#estimate-btn');
    if(!estimateBtn.prop('disabled')){
        estimateBtn.prop('disabled',true);
    } else {
        estimateBtn.prop('disabled',false);
    }

    console.log('Estimate button toggled')
}


function removeEstimateBtnDisabled(){
    let estimateBtn = $('#estimate-btn');
    if(estimateBtn.prop('disabled')){
        estimateBtn.prop('disabled',false);
    } 
    console.log('Estimate button disabled off')
}

function showEstimate(data) {   
    let dollarUSLocale = Intl.NumberFormat('en-US');
    // console.log("US Locale output: " + dollarUSLocale.format(data));
    let dataJson = JSON.parse(data);
    
    let formattedPriceMax = dollarUSLocale.format(dataJson.estimate_max);
    let formattedPriceMin = dollarUSLocale.format(dataJson.estimate_min);
    $('.estimate-box .estimate-span-box span.estimate').text(formattedPriceMin+' - '+formattedPriceMax);
    
    $('.estimate-span-box').parent().removeClass('d-none');
    // hide disclaimer message
    $('.complete-form-disclaimer').addClass('d-none');
    
    // hide estimate button
    toggleEstimateBtnDisabled()   
    removeEstimateBtnClass()   
}


// // tell Barba to use the css plugin
barba.use(barbaCss);

function scrollToTop(elemet) {
    window.scrollTo({ top: 0, behavior: 'smooth' });

}

// set active effect on navigation
function setActiveNavigation(navRouteName){
    let navItems = document.querySelectorAll('.main-navigation-list-item'),
    navItem = document.getElementById('main-navigation-list-item-'+navRouteName);   
    console.log('set active for '+navRouteName);
    navItems.forEach(item => {
        item.classList.remove('active')
    });
    if(navItem){
        navItem.classList.add('active');
    }
}

function setMainNavigationItemEventListener() {
    let navItems = document.querySelectorAll('.main-navigation-list-item');
    navItems.forEach(item => {
        item.addEventListener('click', function() {
            currentRouteName = item.dataset.routeName;      
        });
    });
}

//MAIN NAVIGATION MENU
function OpenMenu() {
    let sidebar = document.querySelector('.sidebar-navigation');
    if (sidebar) {
        let menuBtn = document.querySelector('.menu-btn'),
            buttons = sidebar.querySelectorAll('a'),
            linkButtons = sidebar.querySelectorAll('a.nav-link'),
            submenuButtons = sidebar.querySelectorAll('.nav-link-tree'),
            menuIcon = menuBtn.querySelector('i');

        function toggleSideMenu(){

            sidebar.classList.toggle('active')  
            menuIcon.classList.toggle('fa-bars');
            menuIcon.classList.toggle('fa-times');
        
        }
        
        function toggleSideMenuSubmenu () {
            console.log(this)

        }
        
        // set buttons close action on link click
        linkButtons.forEach(button => {
            button.addEventListener('click',toggleSideMenu);
        })
        
        menuBtn.addEventListener('click', toggleSideMenu);

    }

}

function setNavbarHeight() {
    // Get the DOM Node if you pass in a string
    let menuBtn = document.querySelector('.main-navigation');
    if(menuBtn) {
        let styles = window.getComputedStyle(menuBtn),
            margin = parseFloat(styles['marginTop']) + parseFloat(styles['marginBottom']),
            result = Math.ceil(menuBtn.offsetHeight + margin),
            body = document.querySelector('body');
        
            body.style.setProperty('--nav-height', result + "px");
    }
}

function calcDiffTimes(t1,t2) {
    
    const diffTime = Math.abs(t2 - t1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
    console.log(diffTime + " milliseconds");
}

//BARBA JS
var currentRouteName = 'index';
let timerStart , timerFinish;
barba.init({
    transitions: [
        {
            name: 'home',
            once() {},
            leave() {},
            enter() {},
            
            beforeEnter() {
                timerStart = new Date();
                scrollToTop();
                setActiveNavigation(currentRouteName);
                setMainNavigationItemEventListener();
                // listenToIsotopes();

            },
            
            afterEnter() {
                timerFinish = new Date();
                // calcDiffTimes(timerStart,timerFinish);
                setEstimateButton();
                setOwlCarousel();
                setScrollTriggerHome();
                setParticlesJs();
                // videoLazyLoader();
                // listenToIsotopes();
            },
            
            afterOnce() {
                setEstimateButton();
                setOwlCarousel();
                setScrollTriggerHome();
                setParticlesJs();
                setMainNavigationItemEventListener();
            },
            
            beforeLeave() {
                killScrollTriggerHome();
            }
        }
    ],
    views: [{
        namespace: 'portfolio',
        beforeEnter() {

        },
        afterEnter() {
            listenToIsotopes();
        }
      }]
});    

//DEV HELPERS
function debounce(func){
    var timer;
    return function(event){
        if(timer) clearTimeout(timer);
        timer = setTimeout(func,100,event);
    };
}

function iOS() {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}


//RUNTIME
window.onload = function() {
    setNavbarHeight();
    OpenMenu();
    if(document.querySelector('.logo.animated')){
        animateLogo();
    }

    // scrollProducts();
    //EVENT LISTENERS
    // openCurtain();
    
    window.addEventListener("resize",debounce(setNavbarHeight));
}


function animateLogo(logo){
    let progressBar = document.querySelector(".circular-progress");
    let valueContainer = document.querySelector(".value-container");
  
    let progressValue = 0;
    let progressEndValue = 100;
    let speed = 12;
  
    let progress = setInterval(() => {
      progressValue++;
      progressBar.style.background = `conic-gradient(
        transparent 0deg,
        transparent  ${360 - (progressValue * 3.6 * (10/12))}deg,
        var(--dlcweb-color-bluesky,cyan) ${360 - progressValue * 3.6 * (8/12)}deg,
        var(--dlcweb-color-blue,blue) ${360 - progressValue * 3.6 * (5/12)}deg,
        var(--dlcweb-color-purple,purple) ${360 - progressValue * 3.6 * (1/12)}deg
      )`;
  
      progressBar.style.transform = `rotate(${progressValue * 3.6 * 1.06}deg)`
  
      if (progressValue == progressEndValue) {
        clearInterval(progress);
      }
    }, speed);
  }


  
function listenToIsotopes(){
    let iso = new Isotope('.grid');
    iso.layout(); 
    let filters = document.querySelectorAll(".btn-isotope-filter");
    filters.forEach(btn => {
        btn.addEventListener('click', (event)=>{
            iso.arrange({
                filter: function(itemElement){
                    let filter = btn.dataset.filter
                    let conditional = itemElement.classList.contains(filter);
                    return conditional ? true : false;
                }
            })
        })
    });
}

